import React, { useCallback, useEffect, useMemo, useState } from "react";

import { GetStaticProps, NextPage } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";

import { FooterProps, Header } from "@/components/layout";
import { useGetProductsListQuery } from "@/components/layout/Header/components/Navigation/graphql/GetProductsList.generated";
import { apiFetchWordPressMediaInitialData } from "@/entities/wordpress/api/media";
import { Media } from "@/entities/wordpress/api/media/types";
import { apiFetchWordPressPagesInitialData } from "@/entities/wordpress/api/pages";
import { fetchWordPressPosts } from "@/entities/wordpress/api/posts";
import { BLOG_REVALIDATE_TIME_SEC } from "@/features/public/blog/constants";
import { useAttachBot, useSchemaLdJsonToContent } from "@/hooks";
import { dataLayerViewAndSelectItemEvent } from "@/lib/dataLayersPush";

import HomeTicker from "./components/HomeTicker";
import MediaPartners from "./components/MediaPartners";
import QuestionsAndAnsweres from "./components/QuestionsAndAnsweres";
import WhatYouNeedToKnow from "./components/WhatYouNeedToKnow";
import { HomePageProps } from "./types";

import styles from "./styles.module.scss";

const BecomeMember = dynamic(() => import("./components/BecomeMember"));
const WebinarsAndPodcasts = dynamic(
  () => import("./components/WebinarsAndPodcasts")
);
const Mortgages = dynamic(() => import("./components/Mortgages"));
const LandlordTools = dynamic(() => import("./components/LandlordTools"));
const StayCompliant = dynamic(() => import("./components/StayCompliant"));
const MashroomTeam = dynamic(() => import("./components/MashroomTeam"));
const Subscribe = dynamic(() => import("./components/Subscribe"));
const Footer = dynamic<FooterProps>(() =>
  import("@/components/layout/Footer").then((module) => module.Footer)
);

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  let media = [] as Media[];
  const page = await apiFetchWordPressPagesInitialData({
    slug: "index-3-0",
  });

  const initialData460 = await fetchWordPressPosts({
    page: 1,
    per_page: 1,
    tags: [460],
  });

  media = [
    ...media,
    await apiFetchWordPressMediaInitialData(
      initialData460.data[0].featured_media
    ),
  ];

  const initialData461 = await fetchWordPressPosts({
    page: 1,
    per_page: 4,
    tags: [461],
  });

  for (const img of initialData461.data) {
    media = [
      ...media,
      // eslint-disable-next-line no-await-in-loop
      await apiFetchWordPressMediaInitialData(img.featured_media),
    ];
  }

  const initialData462 = await fetchWordPressPosts({
    page: 1,
    per_page: 8,
    tags: [462],
  });

  return {
    props: {
      page,
      initialData460,
      initialData461,
      initialData462,
      media,
    },
    revalidate: BLOG_REVALIDATE_TIME_SEC,
  };
};

const HomePage: NextPage<HomePageProps> = ({
  page,
  initialData460,
  initialData461,
  initialData462,
  media,
}) => {
  useAttachBot();

  const homePage = page.data[0].acf;

  const [viewStayCompliant, setViewStayCompliant] = useState<
    boolean | undefined
  >();
  const { data: productsData } = useGetProductsListQuery();
  const productList = useMemo(
    () =>
      (productsData?.products?.list?.extra_services ?? []).filter(
        (product) => product.page_url
      ),
    [productsData?.products?.list?.extra_services]
  );
  const organizationSchema = useSchemaLdJsonToContent(
    homePage.organization,
    "organizationSchema"
  );

  const onScroll = useCallback(
    (ev: Event) => {
      if (
        document.body.clientHeight + window.scrollY > 4300 &&
        !viewStayCompliant
      ) {
        setViewStayCompliant(true);
        dataLayerViewAndSelectItemEvent({
          action: "view_item_list",
          products: productList,
        });
      }
    },
    [productList, viewStayCompliant]
  );

  useEffect(() => {
    if (!viewStayCompliant) {
      window.addEventListener("scroll", onScroll);

      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }

    return undefined;
  }, [onScroll, viewStayCompliant]);

  return (
    <div className={styles.wrap}>
      <Head>
        <title>{homePage.meta_title}</title>
        <meta content={homePage.meta_description} name="description" />
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Head>

      <Header initialData={React.useMemo(() => [page.data[0]], [page.data])} />

      <main className={styles.main}>
        {organizationSchema}
        <HomeTicker content={homePage.main_header} />

        <WhatYouNeedToKnow
          initialData460={initialData460}
          initialData461={initialData461}
          media={media}
          trendingNowTitle={homePage.trending_now_3}
          whatYouNeedToKnowContent={homePage.what_you_need_to_know}
        />

        <MediaPartners />

        <QuestionsAndAnsweres
          initialData462={initialData462}
          title={homePage.stay_up_to_date}
        />

        <BecomeMember content={homePage.become_a_member} />

        <WebinarsAndPodcasts content={homePage.webinars_and_podcasts} />

        <Mortgages
          content={homePage.mashroom_mortgages}
          schemaProductList={homePage.product_list}
        />

        <LandlordTools content={homePage.free_landlord_tools} />

        <StayCompliant
          content={homePage.stay_compliant}
          schemaProductTable={homePage.product_table}
        />

        <MashroomTeam content={homePage.mashroom_team} />

        <Subscribe />
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;
